<template>
  <div>
    <hr class="yellow-rule" />
    <v-container>
      <v-row class="mb-4 mt-4" justify="center">
        <h1 class="text-h3">{{ $t("page_title") }}</h1>
      </v-row>

      <v-row>
        <v-col cols="12">
          <p class="text-body-1 pa-1">{{ $t("intro_paragraph") }}</p>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-btn
          class="mr-2"
          color="secondary"
          rounded
          :to="{
            path: locale.base + '/submit-compensation-selection/verified'
          }"
          large
          dark
        >
          <v-icon left dark large>mdi-plus</v-icon>
          {{ $t("add_compensation") }}
        </v-btn>
      </v-row>
      <v-row justify="center" class="mt-2">
        <router-link
          :to="{ path: locale.base + '/submit-compensation' }"
          class="text-caption unverified-link"
          >{{ $t("add_normal_compensation") }}</router-link
        >
      </v-row>
      <v-row>
        <v-col cols="12">
          <h2 class="text-h4 secondary--text pa-1">{{ $t("faq") }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("what_for") }}</h3>

          <p class="text-body-1 pa-1">
            <i18n path="what_for_explanation">
              <template v-slot:salaries>
                <router-link :to="{ path: locale.base + '/' }">{{
                  $t("salaries")
                }}</router-link>
              </template>
              <template v-slot:here>
                <router-link :to="{ path: locale.base + '/about' }">{{
                  $t("here")
                }}</router-link>
              </template>
            </i18n>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("is_anonymous") }}</h3>
          <p class="text-body-1 pa-1">{{ $t("is_anonymous_explanation") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("what_do_you_do") }}</h3>
          <p class="text-body-1 pa-1">{{ $t("what_do_you_do_explanation") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("how_to_submit") }}</h3>
          <p class="text-body-1 pa-1">
            <i18n path="how_to_submit_explanation">
              <router-link
                :to="{ path: locale.base + '/submit-compensation-selection' }"
                >{{ $t("form") }}</router-link
              >
            </i18n>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("what_happens") }}</h3>
          <p class="text-body-1 pa-1">{{ $t("what_happens_explanation") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="text-h5 pa-1">{{ $t("already_submitted") }}</h3>
          <p class="text-body-1 pa-1">
            {{ $t("already_submitted_explanation") }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<i18n>
{
  "en": {
    "page_title": "Anonymous Verified Salaries",
    "intro_paragraph": "Submit offical proof of compensation such as a job offer letter, promotion or salary change documents, or your income tax declaration form. Offers that you did not accept or compensation documents from a previous company are also fine.",
    "faq": "FAQ",
    "what_for": "What is this for?",
    "what_for_explanation": "We have already collected {salaries} and we want to strengthen the accuracy of our data with verified data points to give you and others more confidence in our information. You can read more about our mission to to empower software engineers with the data they need to make informed decisions {here}.",
    "salaries": "hundreds of salaries",
    "here": "here",
    "is_anonymous": "Is this anonymous?",
    "is_anonymous_explanation": "Yes. We will never share names. Please feel free to black out your name or other personal information on your documents. We may also slightly round the compensation numbers to further protect anonymity.",
    "what_do_you_do": "What do you do with the verification documents?",
    "what_do_you_do_explanation": "We only use the documents for verification. After we verify the information we immediately delete your documents.",
    "how_to_submit": "This sounds cool, how do I submit?",
    "how_to_submit_explanation": "Fill out this {0}.",
    "form": "form",
    "what_happens": "What happens after I submit?",
    "what_happens_explanation": "After we review your submission, your submissions will show up on the site with a \"verified label\". You can opt-out of showing this \"verified\" label in the submission form.",
    "add_compensation": "Add your verified compensation",
    "add_normal_compensation": "Or add an unverified compensation",
    "already_submitted": "I already submitted my salary normally, can I verify it after?",
    "already_submitted_explanation": "Yes! There is a checkbox in the verified salaries form where you can check that it is for a previously entered salary. We will do our best to match the verified salary with the previously submitted unverified entry."
  },
  "ja": {
    "page_title": "匿名の給与認証",
    "intro_paragraph": "給与認証は、オファーレターや給与変更・昇給を証明する書類、確定申告書・源泉徴収票などを、年収情報を証明する書類として提出することです。過去に断ったオファーや、前職からの書類でも構いません。",
    "faq": "FAQ", 
    "what_for": "何のため？",
    "what_for_explanation": "OpenSalaryでは、既に{salaries}を掲載しています。根拠のあるデータを元にデータの正確性を高めることで、信頼される情報を発信できると考えています。「意思決定に必要となるデータを提供しソフトウェア開発に携わるエンジニアをエンパワメントする」という我々のミッションも{here}からご覧ください。",
    "salaries": "多くの年収情報",
    "here": "こちら",
    "is_anonymous": "匿名ですか？",
    "is_anonymous_explanation": "はい、匿名です。名前は公開しません。書類上の氏名やその他個人情報は消して提出いただいて構いません。匿名性を守るために、年収の金額を丸めて掲載する場合もございます。",
    "what_do_you_do": "提出した認証書類はどうなりますか？",
    "what_do_you_do_explanation": "認証の目的でのみ使用します。確認後は、速やかに書類を削除します。",
    "how_to_submit": "どのように提出できますか？",
    "how_to_submit_explanation": "こちらの{0}に記入してご提出ください",
    "form": "フォーム",
    "what_happens": "提出した後は？",
    "what_happens_explanation": "確認の上、年収情報に認証済みマークを付けてOpenSalaryに掲載されます。認証済みと表示したくない場合は、フォーム内の「認証済みマークを表示する。」のチェックを外してご提出ください。",
    "add_compensation": "認証して年収を追加",
    "add_normal_compensation": "または、認証せずに年収を追加",
    "already_submitted": "自分の年収は既に提出済みです。後から認証は可能ですか？",
    "already_submitted_explanation": "はい、可能です。フォーム内の「提出済み年収を認証する。」のチェックを付けてご提出ください。確認の上、提出済みの年収データとマッチさせます。"
  }
}
</i18n>

<script>
import store from "store";

export default {
  components: {},
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used

      title: this.$t("page_title"),
      // all titles will be injected into this template
      titleTemplate: "%s | OpenSalary",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Contribute to salary transparency and fair pay by submitting an anonymous verified salary."
        },
        {
          vmid: "og-title",
          property: "og:title",
          content: "Anonymous Verified Salary Submission | OpenSalary"
        },
        {
          vmid: "og-description",
          property: "og:description",
          content:
            "Help other developers like you and submit your compensation!"
        }
      ]
    };
  },
  created() {},
  computed: {
    locale() {
      return store.getters.locale;
    }
  },
  watch: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.unverified-link {
  text-decoration: none;
}
</style>
